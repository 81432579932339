import React from 'react'
import { graphql } from 'gatsby'
import { Grid } from '@mui/material'

import useDynamicData from '_core/helpers/useDynamicData'

import BlogPostCard from 'public/components/molecules/cards/blogPost/BlogPostCard'
import LandingPage, {
  LandingPageContextProps,
} from 'public/templates/layouts/LandingPage'

interface Props {
  data: Queries.BlogLandingQuery
  pageContext: LandingPageContextProps
}

const MandateLanding = ({ data, pageContext }: Props) => {
  const landing = data.datoCmsLandingPage
  const posts = data.allDatoCmsBlogPost.edges

  const { isDynamic, filterBarProps, contentProps, paginationProps } =
    useDynamicData({
      ...pageContext,
      dynamicType: 'post',
      dynamicContentQuery: dynamicContentQuery,
      dataResultSelector: 'posts',
      withTerms: true,
      withFilterBar: true,
    })

  const gridContainerProps = {
    container: true,
    spacing: 2,
    sx: { alignItems: 'stretch', justifyContent: 'flex-start' },
  }
  const gridItemProps = {
    item: true,
    xs: 12,
    sm: 6,
    lg: 4,
    xl: 3,
  }

  return (
    <LandingPage
      metadata={{
        seoTags: landing.seoMetaTags,
        currentPage: pageContext.currentPage,
      }}
      heroProps={{
        mainHeading: landing.heroMainHeading,
        smallHeading: landing.heroSmallHeading,
        imagePortrait: landing.imagePortrait.gatsbyImageData,
        imageLandscape: landing.imageLandscape.gatsbyImageData,
      }}
      pageContext={pageContext}
      filterBarProps={{ ...pageContext, ...filterBarProps }}
      paginationProps={{ ...pageContext, ...paginationProps }}
      summaryItemName="blog posts"
      pageNum={pageContext.currentPage}
    >
      {isDynamic == true &&
      contentProps &&
      contentProps.items &&
      contentProps.items.length > 0 ? (
        <div className="dynamic-content">
          <Grid {...gridContainerProps}>
            {contentProps.items.map((item: any) => {
              const post: Queries.DatoCmsBlogPost = item.node
              return (
                <Grid key={`dynamic-b-${post.id}`} {...gridItemProps}>
                  <BlogPostCard post={post} isDynamic={isDynamic} />
                </Grid>
              )
            })}
          </Grid>
        </div>
      ) : (
        <Grid {...gridContainerProps}>
          {posts.map((item: any) => {
            const post: Queries.DatoCmsBlogPost = item.node
            return (
              <Grid key={`b-${post.originalId}`} {...gridItemProps}>
                <BlogPostCard post={post} />
              </Grid>
            )
          })}
        </Grid>
      )}
    </LandingPage>
  )
}

export const pageQuery = graphql`
  query BlogLanding($skip: Int!, $perPage: Int!) {
    datoCmsLandingPage(originalId: { eq: "1413574" }) {
      ...LandingPageTitleHero
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsBlogPost(
      limit: $perPage
      skip: $skip
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          ...BlogPostCard
        }
      }
    }
  }
`

const dynamicContentQuery = `
query($filter: PostFilter, $pagination: PaginationOptions!) {
    posts(filter: $filter, pagination: $pagination) {
      totalCount
      edges {
        node {
          title
          id
          publishDate
          slug
          _modelApiKey
          blogType {
            slug
            name
          }
          authors {
            name
            id
            slug
            image {
              url
            }
          }
          image: mainImage {
            url
          }
        }
      }
    }
  }
`

export default MandateLanding
